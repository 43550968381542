import NProgress from 'nprogress';
import { AppRoutes } from './AppRoutes';
import { PATH_PAGE, PATH_APP } from './paths';
import LoadingScreen from 'components/LoadingScreen';
import { Switch, Route, Redirect } from 'react-router-dom';
import React, { Suspense, Fragment, lazy, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles(theme => ({
  '@global': {
    '#nprogress': {
      pointerEvents: 'none',
      '& .bar': {
        top: 0,
        left: 0,
        height: 2,
        width: '100%',
        position: 'fixed',
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`
      },
      '& .peg': {
        right: 0,
        opacity: 1,
        width: 100,
        height: '100%',
        display: 'block',
        position: 'absolute',
        transform: 'rotate(3deg) translate(0px, -4px)',
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`
      }
    }
  }
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false
  });

  useEffect(() => {
    NProgress.done();
    return () => {
      NProgress.start();
    };
  }, []);

  return <Route {...props} />;
}

export function renderRoutes(webRoutes = [], isLoggedIn, user, organizations, organization, apps, changeOrg, resetApps) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {webRoutes.map((route, i) => {
          const hasLayout = !!route.layout;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <RouteProgress
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => {
                return (
                  <React.Fragment>
                    {hasLayout ? (
                      <Layout organizations={organizations} user={user} organization={organization} apps={apps} changeOrg={changeOrg} resetApps={resetApps}>
                        {route.routes ? (
                          renderRoutes(route.routes, isLoggedIn, user, organizations, organization, apps, changeOrg, resetApps)
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                  ) : (
                    <Layout>
                      {route.routes ? (
                        renderRoutes(route.routes)
                      ) : (
                        <Component {...props} />
                      )}
                    </Layout>
                  )}
                </React.Fragment>
                );
              }}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

export const routes = (isLoggedIn, user, organizations, organization, apps) => {
  const addHere = [
    // Others Routes
    {
      exact: true,
      guard: null,
      needsLogin: false,
      path: PATH_PAGE.auth.login,
      component: lazy(() => import('../views/auth/LoginView'))
    },
    {
      exact: true,
      guard: null,
      needsLogin: false,
      path: PATH_PAGE.auth.register,
      component: lazy(() => import('../views/auth/RegisterView'))
    },
    {
      exact: true,
      needsLogin: false,
      path: PATH_PAGE.auth.resetPassword,
      component: lazy(() => import('../views/auth/ResetPasswordView'))
    },
    {
      exact: true,
      needsLogin: false,
      path: PATH_PAGE.auth.updatePassword,
      component: lazy(() => import('../views/auth/UpdatePasswordView'))
    },
    {
      exact: true,
      needsLogin: false,
      path: PATH_PAGE.auth.verify,
      component: lazy(() => import('../views/auth/VerifyCodeView'))
    },
    {
      exact: true,
      needsLogin: false,
      path: PATH_PAGE.auth.root,
      component: () => <Redirect to={PATH_PAGE.auth.login} />
    },
  ];

  if (isLoggedIn) {
    addHere.push({
      exact: true,
      needsLogin: false,
      path: '/',
      component: () => <Redirect to={PATH_APP.main.dashboard} />
    });
    addHere.push(AppRoutes(isLoggedIn, user, organizations, organization, apps));
  } else {
    addHere.push({
      exact: true,
      needsLogin: false,
      path: '/',
      component: () => <Redirect to={PATH_PAGE.auth.login} />
    });
    addHere.push({
      exact: true,
      needsLogin: false,
      path: '/app/dashboard',
      component: () => <Redirect to={PATH_PAGE.auth.login} />
    });
    addHere.push({
      exact: true,
      needsLogin: false,
      path: '/app/view/:id',
      component: () => <Redirect to={PATH_PAGE.auth.login} />
    });
  }

  return addHere;
};

export default routes;
