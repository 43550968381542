import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import VersionVisits from '../general/DashboardAnalyticsView/VersionVisits';
import VersionRates from '../general/DashboardAnalyticsView/VersionRates';

class AppDashboard extends Component {
  render() {
    const { app }  = this.props;
    return (
      <Grid container direction="row" item xs={12} spacing={2}>
        <Grid item xs={12} >
          <VersionVisits app={app} />
        </Grid>
        <Grid item xs={12}>
          <VersionRates app={app} />
        </Grid>
      </Grid>
    )
  }
}

export default AppDashboard;
