import React, { useState } from 'react';
import * as Yup from 'yup';
import { Box, Container } from '@material-ui/core';
import { useFormik } from 'formik';
import StepOne from './StepOne';
import DialogDesign from 'views/app/DialogDesign';
import StepTwo from './StepTwo';
import useIsMountedRef from 'hooks/useIsMountedRef';
import { useSnackbar } from 'notistack';
import client from 'client';

const StepOneSchema = Yup.object().shape({
  platform: Yup.string()
    .required('Choose a platform.'),
  app_name: Yup.string()
    .required('App Name is required'),
  initial_version: Yup.string()
    .required('Initial version is required'),
  redirectUrl: Yup.string().required('An App Store URL is required'),
});

function OrganizationOnboarding({ organization, resetApps }) {
  const [step, setStep] = useState(1);
  const [app, setApp] = useState(null);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const stepOneFormik = useFormik({
    initialValues: {
      app_name: '',
      initial_version: '',
      platform: '',
      redirectUrl: '',
    },
    validationSchema: StepOneSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      try {
        const data = {
          name: values.app_name,
          platform: values.platform,
          redirect_url_attributes: { redirect_url: values.redirectUrl },
          versions_attributes: [ { version_number: values.initial_version }],
        }

        const app = await client.createApp(organization, data);
        setApp(app);
        setStep(2);
      } catch(e) {
        const internalError = e.response.data;
        if (!!internalError) {
          const code = internalError.errors.map(e => e.error_code)[0];
          const message = internalError.errors.map(e => e.message)[0];
          if (isMountedRef.current) {
            setSubmitting(false);
            setErrors({ afterSubmit: code });
            enqueueSnackbar(message, { variant: 'error' });
          }
        }

        if (e.message === "Network Error") {
          enqueueSnackbar('There was a problem connecting to the server', { variant: 'error' });
        }
      }
    }
  })

  return (
    <Container>
      <Box sx={{ mx: 'auto' }}>
        <Box sx={{ mt: 5, mb: 1 }}>
          <React.Fragment>
            {step === 1 &&
              <StepOne formik={stepOneFormik} />
            }
            {step === 2 && app && (
              <DialogDesign app={app} handleSuccess={() => setStep(3)} />
            )}
            {step === 3 && app && (
              <StepTwo app={app} />
            )}
          </React.Fragment>
        </Box>
      </Box>
    </Container>
  );
}

export default OrganizationOnboarding;
