import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControlLabel,
  DialogActions,
  Switch,
} from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import client from 'client';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import useIsMountedRef from 'hooks/useIsMountedRef';

const CreateVersionSchema = Yup.object().shape({
  versionNumber: Yup.string().required('A Version Number is required')
})

function CreateVersionModal({ app, handleClose, open, handleSuccess }) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      versionNumber: '',
      redirectUrl: '',
      versionEnabled: true,
      forceUpgrade: false,
    },
    validationSchema: CreateVersionSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const version = await client.createNewVersion(app, values.versionEnabled, values.versionNumber.trim(), values.redirectUrl.trim(), values.forceUpgrade)
        handleSuccess(version);
      } catch(e) {
        const internalError = e.response.data;
        if (!!internalError) {
          const code = internalError.errors.map(e => e.error_code)[0];
          const message = internalError.errors.map(e => e.message)[0];
          if (isMountedRef.current) {
            setSubmitting(false);
            setErrors({ afterSubmit: code });
            enqueueSnackbar(message, { variant: 'error' });
          }
        }

        if (e.message === "Network Error") {
          enqueueSnackbar('There was a problem connecting to the server', { variant: 'error' });
        }
      }
    }
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps
  } = formik;

  return (
    <Dialog open={open} maxWidth="lg" onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Publish A New Version of {app.name}</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              label="Version Number"
              fullWidth
              {...getFieldProps('versionNumber')}
              error={Boolean(touched.versionNumber && errors.versionNumber)}
              helperText={Boolean(touched.versionNumber && errors.versionNumber)}
            />
            <Box sx={{ mb: 2 }} />
            <TextField
              autoFocus
              label="App Store URL"
              fullWidth
              {...getFieldProps('redirectUrl')}
              error={Boolean(touched.redirectUrl && errors.redirectUrl)}
              helperText="The App Store URL for this specific version."
            />
            <Box sx={{ mb: 2 }} />
            <FormControlLabel
              control={
                <Switch
                  {...getFieldProps('versionEnabled')}
                />
              }
              label="Enabled"
            />
            <Box sx={{ mb: 2 }} />
            <FormControlLabel
              control={
                <Switch {...getFieldProps('forceUpgrade')} />
              }
              label="Force Upgrade"
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              size="medium"
              variant="contained"
              pending={isSubmitting}
            >
              Publish Version
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

export default CreateVersionModal;
