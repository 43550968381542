// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS = {
  auth: '/auth',
  app: '/app',
  docs: '/docs',
};

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    register: path(ROOTS.auth, '/register'),
    resetPassword: path(ROOTS.auth, '/reset-password'),
    updatePassword: path(ROOTS.auth, '/update-password/:token'),
    verify: path(ROOTS.auth, '/verify')
  },
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment'
};

export const PATH_HOME = {
  components: '/components',
  cloud: 'https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0',
  purchase: 'https://material-ui.com/store/items/minimal-dashboard/',
  dashboard: ROOTS.app
};

export const PATH_APP = {
  root: ROOTS.app,
  main: {
    root: path(ROOTS.app, '/dashboard'),
    dashboard: path(ROOTS.app, '/dashboard'),
  },
  management: {
    root: path(ROOTS.app, '/management'),
    user: {
      root: path(ROOTS.app, '/management/user'),
      profile: path(ROOTS.app, '/management/user/profile'),
      cards: path(ROOTS.app, '/management/user/card'),
      list: path(ROOTS.app, '/management/user/list'),
      account: path(ROOTS.app, '/management/user/account')
    },
  },
};

export const PATH_DOCS = {
  root: ROOTS.docs,
  introduction: path(ROOTS.docs, '/introduction'),
  started: path(ROOTS.docs, '/getting-started'),
  // Theme UI
  color: path(ROOTS.docs, '/color'),
  typography: path(ROOTS.docs, '/typography'),
  icon: path(ROOTS.docs, '/icon'),
  shadows: path(ROOTS.docs, '/shadows'),
  components: path(ROOTS.docs, '/components'),
  important: path(ROOTS.docs, '/important'),

  // Development
  routing: path(ROOTS.docs, '/routing'),
  environmentVariables: path(ROOTS.docs, '/environment-variables'),
  stateManagement: path(ROOTS.docs, '/state-management'),
  apiCalls: path(ROOTS.docs, '/api-calls'),
  analytics: path(ROOTS.docs, '/analytics'),
  authentication: path(ROOTS.docs, '/authentication'),
  multiLanguage: path(ROOTS.docs, '/multi-language'),
  formHelper: path(ROOTS.docs, '/form-helper'),

  // Changelog
  support: path(ROOTS.docs, '/support'),
  changelog: path(ROOTS.docs, '/changelog')
};
