import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import client from 'client';
import axios from 'axios';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/core/Skeleton';
import { ColorPicker, createColor } from 'material-ui-color';
import { withSnackbar } from 'notistack';

const FONT_FAMILY = ['Roboto', 'Raleway', 'Montserrat', 'Lato', 'Rubik'];
const FONT_SIZE = [
  8,
  9,
  10,
  12,
  14,
  16,
  20,
  24,
  32,
  42,
  54,
  68,
  84,
  98
];

const styles = {
  iphoneInternal: {
    border: '1px solid #000000',
    borderRadius: 8,
    backgroundColor: '#FFF',
    minHeight: '640px',
    padding: 8,
  },
  controlPanel: {
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
  },
  canvas: {
    padding: 8
  },
  imgPreview: {
    margin: 'auto',
  }
}

class DialogDesign extends Component {
  state = {
    id: null,
    layoutExpanded: true,
    titleExpanded: true,
    subtitleExpanded: false,
    buttonConfigurationExpanded: false,
    logoExpanded: false,
    fonts: [],
    expanded: true,
    logo: null,
    logo2x: null,
    logo3x: null,
    title_configuration: {
      text: "Default Title",
      background_color: createColor("#666666"),
      foreground_color: createColor("#FFFFFF"),
      font_configuration: {
        name: "Roboto",
        size: 16
      }
    },
    subtitle_configuration: {
      text: "Default Subtitle",
      background_color: createColor("#666666"),
      foreground_color: createColor("#FFFFFF"),
      font_configuration: {
        name: "Roboto",
        size: 16
      }
    },
    button_configuration: {
      text: "Upgrade",
      background_color: createColor("#666666"),
      foreground_color: createColor("#FFFFFF"),
      font_configuration: {
        name: "Roboto",
        size: 14
      }
    },
  }

  componentDidMount() {
    const { app } = this.props;
    const { dialog } = app;
    this.setStateFromDialog(dialog);
  }

  setStateFromDialog = (dialog) => {
    const { app } = this.props;
    const { title_configuration, button_configuration, subtitle_configuration } = dialog;
    let newState = {};
    [title_configuration, button_configuration, subtitle_configuration].forEach((config) => {
      config.background_color = createColor(config.background_color);
      config.foreground_color = createColor(config.foreground_color)
    });

    newState['title_configuration'] = title_configuration;
    newState['button_configuration'] = button_configuration;
    newState['subtitle_configuration'] = subtitle_configuration;

    newState.id = dialog.id;

    if (!!app.dialog.logo_url) {
      newState.logo = app.dialog.logo_url;
      newState.logo2x = app.dialog.logo_url_2x;
      newState.logo3x = app.dialog.logo_url_3x;
    }

    this.setState(newState);
  }

  uploadLogo = async (file, stateAttr, url) => {
    const { app } = this.props;
    try {
      const presignedUrl = await client.fetchPresignedUrl(app.organization, file.name.replaceAll(' ', '_'));
      const s3Url = new URL(presignedUrl.url).origin + new URL(presignedUrl.url).pathname

      await axios.put(presignedUrl.url, file, { headers: { 'Content-Type': file.type }})
      const fr = new FileReader()
      const _self = this;
      fr.readAsDataURL(file)
      fr.onload = function(e) {
        _self.setState({
          [stateAttr]: e.target.result,
          [url]: s3Url
        });
      }
    } catch(e) {
      alert(e)
    }
  }

  uploadLogo1x = async (e) => {
    const file = e.target.files[0];
    await this.uploadLogo(file, 'logo', 'logoUrl')
  }

  uploadLogo2x = async (e) => {
    const file = e.target.files[0];
    await this.uploadLogo(file, 'logo2x', 'logoUrl2x')
  }

  uploadLogo3x = async (e) => {
    const file = e.target.files[0];
    await this.uploadLogo(file, 'logo3x', 'logoUrl3x');
  }

  updateDialog = async (e) => {
    const { app, enqueueSnackbar, handleSuccess } = this.props;
    const { id, title_configuration, subtitle_configuration, button_configuration, logoUrl, logoUrl2x, logoUrl3x } = this.state;
    title_configuration.background_color = `#${title_configuration.background_color.hex}`;
    title_configuration.foreground_color = `#${title_configuration.foreground_color.hex}`;
    subtitle_configuration.background_color = `#${subtitle_configuration.background_color.hex}`;
    subtitle_configuration.foreground_color = `#${subtitle_configuration.foreground_color.hex}`;
    button_configuration.background_color = `#${button_configuration.background_color.hex}`;
    button_configuration.foreground_color = `#${button_configuration.foreground_color.hex}`;
    const data = {
      id: id,
      title_configuration,
      subtitle_configuration,
      button_configuration,
      logo_url: logoUrl,
      logo_url_2x: logoUrl2x,
      logo_url_3x: logoUrl3x
    };

    try {
      const resp = await client.updateDialog(app, data);
      this.setStateFromDialog(resp.data.dialog);
      enqueueSnackbar('Saved', { variant: 'success' });
      if (handleSuccess) {
        handleSuccess();
      }
    } catch(e) {
      const internalError = e.response.data;
      if (!!internalError) {
        const message = internalError.errors.map(e => e.message)[0];
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
  }

  handleChange = (panel) => {
    this.setState({
      expanded: panel
    });
  }

  changeTitleText = (event) => {
    const { title_configuration } = this.state;
    title_configuration.text = event.target.value;
    this.setState({
      title_configuration: title_configuration
    });
  }

  changeTitleFont = (event) => {
    const { title_configuration } = this.state;
    title_configuration.font_configuration.name = event.target.value
    this.setState({
      title_configuration: title_configuration
    });
  }

  changeTitleFontSize = (event) => {
    const { title_configuration } = this.state;
    title_configuration.font_configuration.size = event.target.value;
    this.setState({
      title_configuration: title_configuration
    });
  }

  changeTitleFontColor = (color) => {
    const { title_configuration } = this.state;
    title_configuration.foreground_color = color;
    this.setState({
      title_configuration: title_configuration
    });
  }

  changeSubtitleText = (event) => {
    const { subtitle_configuration } = this.state;
    subtitle_configuration.text = event.target.value;
    this.setState({
      subtitle_configuration: subtitle_configuration
    })
  }

  changeSubtitleFont = (event) => {
    const { subtitle_configuration } = this.state;
    subtitle_configuration.font_configuration.name = event.target.value;
    this.setState({
      subtitle_configuration: subtitle_configuration
    })
  }

  changeSubtitleFontSize = (event) => {
    const { subtitle_configuration } = this.state;
    subtitle_configuration.font_configuration.size = event.target.value;
    this.setState({
      subtitle_configuration: subtitle_configuration
    })
  }

  changeSubtitleFontColor = (color) => {
    const { subtitle_configuration } = this.state;
    subtitle_configuration.foreground_color = color;
    this.setState({
      subtitle_configuration: subtitle_configuration
    });
  }

  changeButtonText = (event) => {
    const { button_configuration } = this.state;
    button_configuration.text = event.target.value;
    this.setState({
      button_configuration: button_configuration
    });
  }

  changeButtonBackgroundColor = (color) => {
    const { button_configuration } = this.state;
    button_configuration.background_color = color;
    this.setState({
      button_configuration: button_configuration
    });
  }

  changeButtonTextColor = (color) => {
    const { button_configuration } = this.state;
    button_configuration.foreground_color = color;
    this.setState({
      button_configuration: button_configuration
    });
  }

  changeButtonFontSize = (event) => {
    const { button_configuration } = this.state;
    button_configuration.font_configuration.size = event.target.value
    this.setState({
      button_configuration: button_configuration
    });
  }

  changeButtonFont = (event) => {
    const { button_configuration } = this.state;
    button_configuration.font_configuration.name = event.target.value
    this.setState({
      button_configuration: button_configuration,
    });
  }

  changeBackgroundColor = (color) => {
    const { title_configuration, subtitle_configuration } = this.state;
    title_configuration.background_color = color;
    subtitle_configuration.background_color = color;

    this.setState({
      title_configuration: title_configuration,
      subtitle_configuration: subtitle_configuration,
    })
  }

  render() {
    const { classes, handleSuccess } = this.props;
    const { title_configuration, subtitle_configuration, button_configuration, subtitleExpanded, logoExpanded, logo, logo2x, logo3x, layoutExpanded, titleExpanded, buttonConfigurationExpanded } = this.state;

    return (
      <Grid container direction="row" spacing={2}>
        {!!handleSuccess && (
          <Grid item xs={12}>
            <Typography variant="h3">Design Your Dialog</Typography>
          </Grid>
        )}
        <Grid item xs={5} container direction="row">
          <Grid item xs={12}>
            <Accordion expanded={layoutExpanded} onChange={() => { this.setState({ layoutExpanded: !layoutExpanded }) }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="subtitle1" sx={{ width: '33%', flexShrink: 0 }}>
                  Layout
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row" spacing={1} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={2}>
                    <ColorPicker defaultValue="#000" value={title_configuration.background_color} hideTextfield onChange={this.changeBackgroundColor} />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="subtitle2">Background Color</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={logoExpanded} onChange={() => { this.setState({ logoExpanded: !logoExpanded }) }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography variant="subtitle1" sx={{ width: '33%', flexShrink: 0 }}>Images</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row" spacing={2} justifyContent="center" alignItems="center">
                  <Grid item xs={12} container direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={4} container direction="column" justifyContent="space-around" alignItems="stretch" spacing={1}>
                      <Grid item xs={12} >
                        {logo ?
                          <img src={logo} className={classes.imgPreview} alt="logo1x" />
                        :
                          <Skeleton animation={false} variant="rectangular" width={128} style={{ margin: 'auto' }} height={128}/>
                        }
                      </Grid>
                    </Grid>
                    <Grid item xs={4} container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                      <Grid item xs={12} >
                        {logo2x ? (
                          <img src={logo2x} className={classes.imgPreview} alt="logo2x" />
                        ) :
                          <Skeleton animation={false} variant="rectangular" style={{ margin: 'auto' }} width={128} height={128}/>
                        }
                      </Grid>
                    </Grid>
                    <Grid item xs={4} container direction="column" justifyContent="center" alignItems="center" spacing={1}>
                      <Grid item xs={12} >
                        { logo3x ?
                          <img src={logo3x} className={classes.imgPreview} alt="logo3x" />
                        :
                          <Skeleton animation={false} variant="rectangular" style={{ margin: 'auto' }} width={128} height={128} />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                      <Typography variant="body1" align="center">
                        1x
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" align="center">
                        2x
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="body1" align="center">
                        3x
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container direction="row" spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={4}>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        onChange={this.uploadLogo1x}
                        multiple
                        type="file"
                      />
                      <label htmlFor="contained-button-file">
                        <Button fullWidth variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                          Upload
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={4}>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file-2x"
                        onChange={this.uploadLogo2x}
                        type="file"
                      />
                      <label htmlFor="contained-button-file-2x">
                        <Button  fullWidth variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                          Upload
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={4}>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="contained-button-file-3x"
                        onChange={this.uploadLogo3x}
                        type="file"
                      />
                      <label htmlFor="contained-button-file-3x">
                        <Button  fullWidth variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                          Upload
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={titleExpanded} onChange={() => { this.setState({ titleExpanded: !titleExpanded }) }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography variant="subtitle1" sx={{ width: '33%', flexShrink: 0 }}>Title</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row" spacing={2} justifyContent="flex-start" alignitems="center">
                  <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth value={title_configuration.text} label="Title" onChange={ this.changeTitleText }></TextField>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField fullWidth variant="outlined" select value={title_configuration.font_configuration.name} onChange={this.changeTitleFont} label="Font">
                      {FONT_FAMILY.map((font) => (
                        <MenuItem key={font} value={font}><Typography style={{ fontFamily: font }}>{font}</Typography></MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField fullWidth variant="outlined" select label="Font Size" value={title_configuration.font_configuration.size} onChange={this.changeTitleFontSize}>
                      {FONT_SIZE.map((font_size) => (
                        <MenuItem key={font_size} value={font_size}><Typography>{font_size}</Typography> </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item xs={2}>
                      <ColorPicker defaultValue="#000" value={title_configuration.foreground_color} hideTextfield onChange={this.changeTitleFontColor} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="subtitle2">Title Color</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={subtitleExpanded} onChange={() => { this.setState({ subtitleExpanded: !subtitleExpanded }) }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography variant="subtitle1" sx={{ width: '33%', flexShrink: 0 }}>
                  Subtitle
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row" spacing={2} justifyContent="flex-start" alignitems="center">
                  <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth value={subtitle_configuration.text} label="Subtitle" onChange={ this.changeSubtitleText }></TextField>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField fullWidth variant="outlined" select value={subtitle_configuration.font_configuration.name} onChange={this.changeSubtitleFont} label="Font">
                      {FONT_FAMILY.map((font) => (
                        <MenuItem key={font} value={font}><Typography style={{ fontFamily: font }}>{font}</Typography></MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField fullWidth variant="outlined" select label="Font Size" value={subtitle_configuration.font_configuration.size} onChange={this.changeSubtitleFontSize}>
                      {FONT_SIZE.map((font_size) => (
                        <MenuItem key={font_size} value={font_size}><Typography>{font_size}</Typography> </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Grid item xs={2}>
                      <ColorPicker defaultValue="#000" value={subtitle_configuration.foreground_color} hideTextfield onChange={this.changeSubtitleFontColor} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="subtitle2">Subtitle Font Color</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={buttonConfigurationExpanded} onChange={() => { this.setState({ buttonConfigurationExpanded: !buttonConfigurationExpanded }) }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography variant="subtitle1" sx={{ flexShrink: 0 }}>Button Configuration</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                  <Grid item xs={12}>
                    <TextField variant="outlined" fullWidth value={button_configuration.text} label="Button Text" onChange={this.changeButtonText}></TextField>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField fullWidth variant="outlined" select value={button_configuration.font_configuration.name} onChange={this.changeButtonFont} label="Button Font">
                      {FONT_FAMILY.map((font) => (
                        <MenuItem key={font} value={font}><Typography style={{ fontFamily: font }}>{font}</Typography></MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField fullWidth variant="outlined" select label="Font Size" value={button_configuration.font_configuration.size} onChange={this.changeButtonFontSize}>
                      {FONT_SIZE.map((font_size) => (
                        <MenuItem key={font_size} value={font_size}><Typography >{font_size}</Typography> </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={2}>
                      <ColorPicker defaultValue="#000" value={button_configuration.background_color} hideTextfield onChange={this.changeButtonBackgroundColor} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="subtitle2"> Button Background Color </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <ColorPicker defaultValue="#000" value={button_configuration.foreground_color} hideTextfield onChange={this.changeButtonTextColor} />
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="subtitle2">Button Text Color</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" spacing={1}>
              <Grid item xs={12}>
                <Button variant="contained" size="large" fullWidth onClick={this.updateDialog}>Save</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={7} container direction="row" justifyContent="center">
          <Grid item xs={12} className={classes.canvas} component={Paper} container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={6} style={{ backgroundColor: `#${title_configuration.background_color.hex}` }} className={classes.iphoneInternal} container direction="column" justifyContent="space-around" alignItems="stretch">
              <Grid item xs={3}>
                <Typography align="center"
                  style={{
                    fontSize: `${title_configuration.font_configuration.size}px`,
                    fontFamily: title_configuration.font_configuration.name,
                    color: `#${title_configuration.foreground_color.hex}`
                  }}
                >{title_configuration.text}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item>
                    {logo ? (
                      <img alt="logo" srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`} />
                    ) : (
                      <Skeleton variant="rectangular" animation={false} height={256} width={256} />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  align="center"
                  style={{
                    fontSize: `${subtitle_configuration.font_configuration.size}px`,
                    fontFamily: subtitle_configuration.font_configuration.name,
                    color: `#${subtitle_configuration.foreground_color.hex}`,
                  }}
                >{subtitle_configuration.text}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    backgroundColor: `#${button_configuration.background_color.hex}`,
                    color: `#${button_configuration.foreground_color.hex}`,
                    fontFamily: button_configuration.font_configuration.name,
                    fontSize: `${button_configuration.font_configuration.size}px`
                  }}
                >{button_configuration.text}</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(DialogDesign));
