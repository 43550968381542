import React, { useState, useEffect } from 'react';
import Page from 'components/Page';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import OrganizationOnboarding from 'components/OrganizationOnboarding/index';
import StepTwo from 'components/OrganizationOnboarding/StepTwo';
import AppView from 'views/app/AppView';

// ----------------------------------------------------------------------

const useStyles = makeStyles(theme => ({
}));

function DashboardEcommerceView({ apps, organization, resetApps }) {
  const [app, setApp] = useState(null);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (apps.length === 0) {
      setShowOnboarding(true);
    } else {
      setApp(apps[0]);
    }
  }, [apps])

  return (
    <Page title="Dashboard" className={classes.root}>
      <Container maxWidth="xl">
        <>
          {showOnboarding ? (
            <OrganizationOnboarding organization={organization} resetApps={resetApps} />
          ) : (
            <>
              {(app && app.first_event_received) ? (
                <AppView app={app} />
              ) : (
                <StepTwo app={app} />
              )}
            </>
          )}
        </>
      </Container>
    </Page>
  );
}

export default DashboardEcommerceView;
