import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { merge } from 'lodash';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { fNumber } from 'utils/formatNumber';
import { ApexChartsOption } from 'components/Charts/Apexcharts';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Card, CardHeader, CardActions, Typography } from '@material-ui/core';
import { MCircularProgress } from '@material-extend';
import client from 'client';
import { cloneDeep } from 'lodash';
import { ReactSVG } from 'react-svg';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 464
  },
}));

// ----------------------------------------------------------------------

VersionRates.propTypes = {
  className: PropTypes.string
};

function VersionRates({ app, className, ...other }) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState([]);
  let [chartOptions, setChartOptions] = useState(
    merge(ApexChartsOption(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: seriesName => fNumber(seriesName),
        title: {
          formatter: function(seriesName) {
            return '#';
          }
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '40%', endingShape: 'rounded' }
    },
    xaxis: {
      categories: ["0.0.1", "0.0.2", "0.0.3"]
    }
  }));

  useEffect(() => {
    async function fetchData() {
      try {
        const stats = await client.fetchAppStats(app.id);
        const versionCount = stats.version_count;
        const data = [{ data: Object.values(versionCount) }];
        const options = cloneDeep(chartOptions)
        options.xaxis.categories = Object.keys(versionCount);;
        setChartOptions(options);
        setChartData(data);
        setIsLoading(false);
      } catch(e) {
        console.log(e)
      }
    }
    fetchData();
  }, [app, chartOptions]);

  return (
    <Card className={clsx(classes.root, className)} {...other}>
      {isLoading ? (
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <MCircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          {!!chartData[0] && chartData[0].data.length > 0 ? (
            <>
              <CardHeader title="Popular Versions" />
              <Box sx={{ mx: 3 }}>
                <ReactApexChart
                  type="bar"
                  height={364}
                  series={chartData}
                  options={chartOptions}
                />
                <CardActions>
                  <Typography align="right" variant="caption">Data is shown for the last 7 days.</Typography>
                </CardActions>
              </Box>
            </>
          ) : (
            <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.root}>
              <Grid item>
                <ReactSVG src="/static/illustrations/illustration_empty_content.svg" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" align="center">
                  We&apos;re crunching the numbers.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" align="center">
                  Check back soon for more data.
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Card>
  );
}

export default VersionRates;
