import React from 'react';
import { MIcon } from '@material-extend';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidSharpIcon from '@material-ui/icons/AndroidSharp';
import LanguageIcon from '@material-ui/icons/Language';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import windowsFilled from '@iconify-icons/ant-design/windows-filled';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

const path = name => `/static/icons/navbar/${name}.svg`;

const ICONS = {
  authenticator: <MIcon src={path('ic_authenticator')} />,
  blog: <MIcon src={path('ic_blog')} />,
  calendar: <MIcon src={path('ic_calendar')} />,
  cart: <MIcon src={path('ic_cart')} />,
  charts: <MIcon src={path('ic_charts')} />,
  chat: <MIcon src={path('ic_chat')} />,
  components: <MIcon src={path('ic_components')} />,
  dashboard: <MIcon src={path('ic_dashboard')} />,
  editor: <MIcon src={path('ic_editor')} />,
  elements: <MIcon src={path('ic_elements')} />,
  error: <MIcon src={path('ic_error')} />,
  mail: <MIcon src={path('ic_mail')} />,
  map: <MIcon src={path('ic_map')} />,
  page: <MIcon src={path('ic_page')} />,
  user: <MIcon src={path('ic_user')} />,
  upload: <MIcon src={path('ic_upload')} />,
  copy: <MIcon src={path('ic_copy')} />,
  carousel: <MIcon src={path('ic_carousel')} />,
  language: <MIcon src={path('ic_language')} />,
  "iOS": <AppleIcon />,
  "Android": <AndroidSharpIcon />,
  "Web": <LanguageIcon />,
  "Mac": <LaptopMacIcon />,
  "Windows": <Icon icon={windowsFilled} width={24} height={24} />,
};

export const MenuLinks = (apps) => {
  const appItems = apps.map((app) => {
    return {
      title: app.name,
      icon: ICONS[app.platform],
      href: `/app/view/${app.id}`
    }
  });

  return [
    // APP
    // ----------------------------------------------------------------------
    {
      subheader: 'apps',
      items: appItems,
    }
  ]
};
