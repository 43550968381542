import { merge } from 'lodash';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { ApexChartsOption } from 'components/Charts/Apexcharts';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardHeader, Box, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import client from 'client';
import moment from 'moment';
import { MCircularProgress } from '@material-extend';
import { ReactSVG } from 'react-svg';

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 464
  }
}));

// ----------------------------------------------------------------------

VersionVisits.propTypes = {
  className: PropTypes.string
};

function VersionVisits({ app, className, ...other }) {
  const [chartData, setChartData] = useState([]);
  let [chartOptions, setChartOptions] = useState(merge(ApexChartsOption(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '12%', endingShape: 'rounded' } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: [],
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function(y) {
          if (typeof y !== 'undefined') {
            return y.toFixed(0) + ' visits';
          }
          return y;
        }
      }
    }
  }));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        let stats = await client.fetchAppStats(app.id);
        stats = stats.stats;
        if (stats.length > 0) {
          let allDates = stats.map((stat) => stat.date);
          allDates = _.uniq(allDates);
          allDates = allDates.map((date) => new Date(date))
          allDates = _.sortBy(allDates);
          const formattedDates = allDates.map((dt) => moment(dt).format('MM/DD/YYYY'));

          const groups = stats.reduce((groups, item) => {
            const group = (groups[item.version] || []);
            group.push(item);
            groups[item.version] = group;
            return groups;
          }, {});

          const versions = Object.keys(groups);
          const chartDataResults = [];
          versions.forEach((version) => {
            const group = groups[version];
            const versionDataResults = {};
            versionDataResults.name = version;
            versionDataResults.type = 'line';
            const data = [];
            allDates.forEach((date) => {
              const dataForDate = group.find((vn) => {
                return new Date(vn.date).getTime() === date.getTime();
              });
              if (!!dataForDate) {
                data.push(dataForDate.count);
              } else {
                data.push(0);
              }
            });

            versionDataResults.data = data;
            chartDataResults.push(versionDataResults);
          });

          setChartData(chartDataResults);
          chartOptions.labels = formattedDates;
          setChartOptions(chartOptions);
        }

        setIsLoading(false)

      } catch(e) {
        console.log(e);
      }
    }
    fetchData();

  }, [app, chartOptions]);

  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, className)} {...other}>
      {isLoading ? (
        <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.root}>
          <Grid item>
            <MCircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
        {chartData.length > 0 ? (
          <>
            <CardHeader title="Versions In Operation" />
            <Box sx={{ mx: 3, mb: 1 }}>
              <ReactApexChart
                type="line"
                height={364}
                series={chartData}
                options={chartOptions}
              />
              <CardActions>
                <Typography align="right" variant="caption">Data is shown for the last 7 days.</Typography>
              </CardActions>
            </Box>
          </>
        ) : (
          <Grid container direction="column" justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item>
              <ReactSVG src="/static/illustrations/illustration_empty_content.svg" />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="center">
                We&apos;re crunching the numbers.
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" align="center">
                Check back soon for more data.
              </Typography>
            </Grid>
          </Grid>
        )}
        </>
      )}
    </Card>
  );
}

export default VersionVisits;
