import Page from 'components/Page';
import { capitalCase } from 'change-case';
import React from 'react';
import HeaderDashboard from 'components/HeaderDashboard';
import { Container, Tab, Tabs } from '@material-ui/core';
import client from 'client';
import LoadingScreen from 'components/LoadingScreen';
import VersionList from './VersionList';
import DialogDesign from './DialogDesign';
import { withStyles } from '@material-ui/styles';
import AppSettings from './AppSettings';
import AppDashboard from './AppDashboard';
import { withSnackbar } from 'notistack';

const styles = {
  root: {},
  tabBar: {
    marginBottom: 40
  }
};

const tabs = (app) => {
  return [
    // {
    //   value: 'dashboard',
    //   component: <AppDashboard app={app} />
    // },
    {
      value: 'versions',
      component: <VersionList app={app} />
    },
    {
      value: 'dialog',
      component: <DialogDesign app={app} />
    },
    {
      value: 'settings',
      component: <AppSettings app={app} />
    }
  ];
}

class AppView extends React.Component {
  state = {
    currentTab: 'versions',
    app: null
  };

  componentDidMount() {
    const { match, app } = this.props;
    if (!!match) {
      const { id } = match.params;
      this.fetchApp(id);
    }

    if (!!app) {
      this.setState({
        app: app
      });
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        this.fetchApp(this.props.match.params.id);
      }
    }
  }

  fetchApp = (id) => {
    const { enqueueSnackbar } = this.props;
    client.fetchApp(id).then((res) => {
      this.setState({
        app: res
      });
    }).catch((e) => {
      const internalError = e.response.data;
      if (!!internalError) {
        const message = internalError.errors.map(e => e.message)[0];
        enqueueSnackbar(message, { variant: 'error' });
      }
    });
  }

  handleChangeTab = (event, newValue) => {
    this.setState({
      currentTab: newValue
    });
  }

  render() {
    const { classes } = this.props;
    const { currentTab, app } = this.state;
    return (
      <React.Fragment>
        {app === null ? (
          <LoadingScreen />
        ) : (
          <Page title={`${app.name}`} className={classes.root}>
            <Container>
              <HeaderDashboard
                heading={app.name}
                subtitle={app.platform}
                links={[]}
              />
              <Tabs
                value={currentTab}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                onChange={this.handleChangeTab}
                className={classes.tabBar}
              >
                {tabs(app).map(tab => (
                  <Tab
                    disableRipple
                    key={tab.value}
                    label={capitalCase(tab.value)}
                    value={tab.value}
                  />
                ))}
                </Tabs>

                {tabs(app).map(tab => {
                  const isMatched = tab.value === currentTab;
                  return isMatched && <React.Fragment>{tab.component}</React.Fragment>;
                })}
            </Container>
          </Page>
        )}
      </React.Fragment>
    );
  }
}

export default withSnackbar(withStyles(styles)(AppView));
