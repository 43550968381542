import React from 'react';
import PropTypes from 'prop-types';
import createAvatar from 'utils/createAvatar';
import { MAvatar } from '@material-extend';

// ----------------------------------------------------------------------

MyAvatar.propTypes = {
  className: PropTypes.string
};

function MyAvatar({ className, profile, profileType, ...other }) {
  const displayName = profileType === "User" ? `${global.currentUser.profile.first_name} ${global.currentUser.profile.last_name}` : `${profile.name}`;
  const photoURL = profileType === "User" ? global.currentUser.profile.avatar_url : '';

  return (
    <MAvatar
      src={photoURL}
      alt={displayName}
      color={photoURL ? 'default' : createAvatar(displayName).color}
      className={className}
      {...other}
    >
      {createAvatar(displayName).name}
    </MAvatar>
  );
}

export default MyAvatar;
