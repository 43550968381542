import React from 'react';
import { Form, FormikProvider } from 'formik';
import { LoadingButton } from '@material-ui/lab';
import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidSharpIcon from '@material-ui/icons/AndroidSharp';

const platforms = [
  { name: 'iOS', label: 'iOS', icon: <AppleIcon /> },
  { name: 'Android', label: 'Android', icon: <AndroidSharpIcon /> },
]

function StepOne({ formik }) {
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        <Typography variant="h3" align="center" gutterBottom>Welcome</Typography>
        <Typography color="textPrimary" align="center">Set Up Your First App</Typography>
        <Box sx={{ mt: 3 }} />
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <TextField
              autoFocus
              select
              fullWidth
              label="Select Platform"
              {...getFieldProps('platform')}
              error={Boolean(touched.platform && errors.platform)}
              helperText={Boolean(touched.platform && errors.platform)}
            >
              {platforms.map((platform) => {
                return (
                  <MenuItem key={platform.name} value={platform.name}>
                    <Grid container direction="row">
                      <Grid item xs={1}>
                        {platform.icon}
                      </Grid>
                      <Grid item xs={9}>
                        <Typography>{platform.name}</Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                )
              })}
            </TextField>
            <Box sx={{ mt: 3 }} />
            <TextField
              fullWidth
              {...getFieldProps('app_name')}
              label="App Name"
              error={
                Boolean(touched.app_name && errors.app_name)
              }
              helperText={touched.app_name && errors.app_name}
            />
            <Box sx={{ mt: 3 }} />
            <TextField
              {...getFieldProps('redirectUrl')}
              label="App Store URL"
              fullWidth
              error={
                Boolean(touched.redirectUrl && errors.redirectUrl)
              }
              helperText="The App Store URL users will use to download new versions of this app."
            />
            <Box sx={{ mb: 3 }} />
            <TextField
              fullWidth
              {...getFieldProps('initial_version')}
              label="Initial Version"
              error={Boolean(touched.initial_verison && errors.initial_version)}
              helperText={touched.initial_version && errors.initial_version}
            />
            <Box sx={{ mt: 3 }}>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                pending={isSubmitting}
              >
                Create App
              </LoadingButton>
            </Box>
          </Form>
        </FormikProvider>
      </Grid>
    </Grid>
  )
}

export default StepOne;
