import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControlLabel,
  DialogActions,
  Switch,
} from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import client from 'client';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import useIsMountedRef from 'hooks/useIsMountedRef';

const EditVersionSchema = Yup.object().shape({
  versionNumber: Yup.string().required('A Version Number is required')
});

function EditVersionModal({ app, handleClose, open, version, handleSuccess }) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      id: version.id,
      versionNumber: version.version_number || '',
      redirectUrl: version.redirect_url || '',
      versionEnabled: version.enabled,
      forceUpgrade: version.force_upgrade,
    },
    validationSchema: EditVersionSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const data = {
          id: values.id,
          version_number: values.versionNumber.trim(),
          redirect_url_attributes: { redirect_url: values.redirectUrl.trim() },
          enabled: values.versionEnabled,
          force_upgrade: values.forceUpgrade,
        }
        const version = await client.updateVersion(values.id, data);
        handleSuccess(version);
      } catch(e) {
        const internalError = e.response.data;
        if (!!internalError) {
          const code = internalError.errors.map(e => e.error_code)[0];
          const message = internalError.errors.map(e => e.message)[0];
          if (isMountedRef.current) {
            setSubmitting(false);
            setErrors({ afterSubmit: code });
            enqueueSnackbar(message, { variant: 'error' });
          }
        }

        if (e.message === "Network Error") {
          enqueueSnackbar('There was a problem connecting to the server', { variant: 'error' });
        }
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps
  } = formik;

  return (
    <Dialog open={open} maxWidth="lg" onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Edit Version {version.version_number}</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              label="Version Number"
              fullWidth
              {...getFieldProps('versionNumber')}
              error={Boolean(touched.versionNumber && errors.versionNumber)}
              helperText={Boolean(touched.versionNumber && errors.versionNumber)}
            />
            <Box sx={{ mb: 2 }} />
            <TextField
              autoFocus
              label="App Store URL"
              fullWidth
              {...getFieldProps('redirectUrl')}
              error={Boolean(touched.redirectUrl && errors.redirectUrl)}
              helperText="The App Store URL for this specific version."
            />
            <Box sx={{ mb: 2 }} />
            <FormControlLabel
              control={
                <Switch
                  {...getFieldProps('versionEnabled')}
                  checked={values.versionEnabled}
                />
              }
              label="Enabled"
            />
            <Box sx={{ mb: 2 }} />
            <FormControlLabel
              control={
                <Switch
                  {...getFieldProps('forceUpgrade')}
                  checked={values.forceUpgrade}
                />
              }
              label="Force Upgrade"
            />
          </DialogContent>
          <DialogActions>
            <LoadingButton
              size="medium"
              variant="contained"
              pending={isSubmitting}
            >
              Update Version
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
}

export default EditVersionModal;
