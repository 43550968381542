import axios from 'axios';
import qs from 'qs';

const headers = {
  'Content-Type': 'application/json',
};

const authToken = localStorage.getItem('Authorization-Token');
if (!!authToken) {
  headers['Authorization-Token'] = authToken;
}

const v1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  headers,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' })
  },
});

let client = {
  fetchInvitations: (organizationId) => v1.get(`/organizations/${organizationId}/invitations`).then(res => res.data),
  createUser: (user) => v1.post('/signup', { user: user }).then(res => res.data),
  createUserWithGoogle: (data) => v1.post('/google_auth', { google: data }).then(res => res.data),
  login: (user) => v1.post('/sessions', { user: user }).then(res => res.data),
  fetchResetPasswordToken: (email) => v1.post('/forgot_password', { email: email }),
  resetPassword: (token, data) => v1.post(`/reset_password?token=${token}`, { user: data }),
  updateUser: (user, data) => v1.patch(`/users/${user.id}`, { user: data }).then(res => res.data),
  updatePassword: (user, data) => v1.patch(`/users/${user.id}/update_password`, { user: data }).then(res => res.data),
  fetchUserPresignedUrl: (user, fileName) => v1.get(`/users/${user.id}/presigned_url?filename=${fileName}`).then(res => res.data),
  createNewVersion: (app, enabled, versionNumber, redirectUrl, forceUpgrade) => v1.post(`apps/${app.id}/versions`, { enabled: enabled, version_number: versionNumber, force_upgrade: forceUpgrade, redirect_url_attributes: { redirect_url: redirectUrl } }).then(res => res.data),
  fetchCurrentUser: () => v1.get('/sessions/me', {}).then(res => res.data),
  fetchUserOrganizations: (user) => v1.get(`/users/${user.id}/organizations`).then(res => res.data),
  fetchOrganizationApps: (organization) => v1.get(`/organizations/${organization.id}/apps`).then(res => res.data),
  fetchOrganizationMembers: (organizationId) => v1.get(`/organizations/${organizationId}/users`).then(res => res.data),
  fetchApp: (id) => v1.get(`/apps/${id}`).then(res => res.data),
  createApp: (organization, data) => v1.post(`/organizations/${organization.id}/apps`, { app: data }).then(res => res.data),
  updateDialog: (app, dialogData) => v1.patch(`/apps/${app.id}`, { dialog_attributes: dialogData }),
  fetchAppVersions: (id) => v1.get(`/apps/${id}/versions`).then(res => res.data),
  fetchAppStats: (id) => v1.get(`/apps/${id}/stats`).then(res => res.data),
  updateVersion: (id, data) => v1.patch(`/versions/${id}`, { version: data }).then(res => res.data),
  fetchPresignedUrl: (org, fileName) => v1.get(`/organizations/${org.id}/presigned_url?filename=${fileName}`).then(res => res.data),
  uploadToS3: (presignedUrl, file) => v1.put(presignedUrl, file).then(res => res.data),
  deleteVersion: (id) => v1.delete(`/versions/${id}`).then(res => res.data),
  sendInvitations: (organizationId, data) => v1.post(`/organizations/${organizationId}/invitations`, data).then(res => res.data),
}

export default client;
