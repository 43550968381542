import React from 'react';
import ThemeConfig from './theme';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { routes, renderRoutes } from 'routes';
import ScrollToTop from 'components/ScrollToTop';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import NotistackProvider from 'components/NotistackProvider';
import LoadingScreen from 'components/LoadingScreen';
import client from 'client';

const history = createBrowserHistory();

class App extends React.Component {
  state = {
    isLoggedIn: false,
    isLoading: true,
    user: null,
    organization: null,
    organizations: [],
    apps: [],
  }

  changeOrg = async (org) => {
    this.setState({
      isLoading: true,
    }, async () => {
      try {
        const apps = await client.fetchOrganizationApps(org);
        localStorage.setItem('organizationId', org.id);
        this.setState({
          isLoading: false,
          organization: org,
          apps: apps.apps,
        })
      } catch(e) {
        console.log(e)
      }
    });
  }

  async componentDidMount() {
    try {
      const user = await client.fetchCurrentUser();
      global.currentUser = user.user;
      let organization;
      const resp = await client.fetchUserOrganizations(user.user);
      const organizations = resp.organizations;
      const savedOrganization = localStorage.getItem('organizationId');
      if (!!savedOrganization) {
        const organizationExistsInCollection = organizations.findIndex((org) => {
          return org.id === savedOrganization
        });

        if (organizationExistsInCollection > -1) {
          organization = organizations[organizationExistsInCollection];
        }
      }

      if (!organization) {
        organization = organizations[0];
        localStorage.setItem('organizationId', organization.id);
      }

      const apps = await client.fetchOrganizationApps(organization);
      this.setState({
        isLoading: false,
        isLoggedIn: true,
        user: user.user,
        organizations: organizations,
        organization: organization,
        apps: apps.apps
      })

    } catch(e) {
      console.log(e)
      this.setState({
        isLoading: false,
        loggedIn: false,
      });
    }
  }

  resetApps = () => {
    const { organization } = this.state;
    client.fetchOrganizationApps(organization).then((res) => {
      this.setState({
        apps: res.apps,
      });
    })
  }

  render() {
    const { isLoading, isLoggedIn, user, organizations, organization, apps } = this.state;
    const webRoutes = routes(isLoggedIn, user, organizations, organization, apps);

    return (
      <ThemeConfig>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <NotistackProvider>
              <Router history={history}>
                <ScrollToTop />
                {renderRoutes(webRoutes, isLoggedIn, user, organizations, organization, apps, this.changeOrg, this.resetApps)}
              </Router>
            </NotistackProvider>
          </LocalizationProvider>
        )}
      </ThemeConfig>
    );
  }
}

export default App;
