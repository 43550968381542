import React from 'react';
import { Button, Card, Grid, TextField, Typography, InputAdornment, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  margin: {
    marginBottom: theme.spacing(3)
  },
}));

function AppSettings({ app }) {
  const classes = useStyles();

  const deleteApp = async () => {
    try {
      window.location.replace('/app/dashboard');
    } catch(e) {
      console.log(e);
    }
  }

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h4">API Keys</Typography>
          <Card className={clsx(classes.root)}>
            <TextField
              disabled
              fullWidth
              type="password"
              className={classes.margin}
              value={app.application_publishable_key}
              label="Application Public Key"
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <CopyToClipboard text={app.application_publishable_key}>
                      <IconButton
                        aria-label="toggle visibility"
                        onClick={() => { console.log('copied') }}
                      >
                        {<FileCopyIcon />}
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} container direction="row" spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h4">Danger</Typography>
          <Card className={clsx(classes.root)}>
            <Grid container direction="row">
              <Grid item xs={12} container direction="row" spacing={2} justifyItems="center" alignItems="center">
                <Grid item xs={9}>
                  <Typography variant="subtitle1">
                    Delete this app
                  </Typography>
                  <Typography variant="subtitle2">
                    Be careful. Deleting an app will re-enable all versions that are currently disabled.
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Button fullWidth variant="contained" color="error" onClick={deleteApp}>Delete</Button>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AppSettings;
