import React from 'react';
import { Button, Box, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {materialDark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import ReactMarkdown from 'react-markdown';
import { ReactSVG } from 'react-svg';

const useStyles = makeStyles((theme) => ({
  waiting: {
    padding: theme.spacing(2),
  },
  control: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
  }
}));

function StepTwo({ app }) {
  const refresh = () => {
    window.location.reload();
  }

  const podFile = "pod 'Killswitched', :git => 'ssh://git@github.com/killswitched/killswitched-ios.git', :tag => '0.2.3'";
  const podInstall = "pod install";
  let appDelegate = "\nimport Killswitched\n\n@UIApplicationMain\n  class AppDelegate: UIResponder, UIApplicationDelegate {\n  func application(_ application: UIApplication,\n    didFinishLaunchingWithOptions launchOptions:\n    [UIApplication.LaunchOptionsKey: Any]?) -> Bool {\n      let rootVC = (self.window?.rootViewController)!\n      Killswitch.shared.configure(clientKey: APP_PUBLISHABLE_KEY, presentationController: rootVC, currentVersion: YOUR_APP_VERSION)\n      return true\n    }\n  }\n}";
  if (!!app) {
    appDelegate = `\nimport Killswitched\n\n@UIApplicationMain\n  class AppDelegate: UIResponder, UIApplicationDelegate {\n  func application(_ application: UIApplication,\n    didFinishLaunchingWithOptions launchOptions:\n    [UIApplication.LaunchOptionsKey: Any]?) -> Bool {\n      let rootVC = (self.window?.rootViewController)!\n      Killswitched.shared.configure(clientKey: "${app.application_publishable_key}", presentationController: rootVC, currentVersion: YOUR_APP_VERSION)\n      return true\n    }\n  }\n}`;
  }

  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container direction="row" spacing={2} component={Paper} className={classes.waiting}>
        <Grid item xs={6} container direction="row" spacing={2} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h3">We&apos;re waiting to receive your first event.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Follow the instructions below to install killswitched in your application. If you need any help contact us.</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button size="large" variant="contained" onClick={refresh}>Refresh the dashboard</Button>
          </Grid>
        </Grid>
        <Grid item xs={6} container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <ReactSVG src="/static/illustrations/illustration_empty_content.svg" />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }} />
      <Typography variant="h3" gutterBottom>Installation</Typography>
      <Box sx={{ mt: 3 }} />
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Typography><ReactMarkdown>Add `'Killswitched'` to your Podfile</ReactMarkdown></Typography>
        </Grid>
        <Grid item xs={12}>
          <SyntaxHighlighter className={classes.control} style={materialDark} language="bash" children={podFile} />
        </Grid>
        <Grid item xs={12}>
          <Typography>Install your dependencies</Typography>
        </Grid>
        <Grid item xs={12}>
          <SyntaxHighlighter className={classes.control} style={materialDark} language="bash" children={podInstall} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">App Delegate</Typography>
        </Grid>
        <Grid item xs={12} >
          <Typography><ReactMarkdown>Import the 'killswitched' module and initialize killswitched in the `application:didFinishLaunchingWithOptions:` method</ReactMarkdown> </Typography>
        </Grid>
        <Grid item xs={12}>
          <SyntaxHighlighter className={classes.control} style={materialDark} language="swift" children={appDelegate} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4"></Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default StepTwo;
