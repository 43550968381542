import React from 'react';
import { Box, Dialog, DialogTitle, DialogContent, TextField, DialogActions, MenuItem } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import client from 'client';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@material-ui/lab';
import useIsMountedRef from 'hooks/useIsMountedRef';

const platforms = ["iOS", "Mac", "Android", "Web"];

const CreateAppSchema = Yup.object().shape({
  appName: Yup.string().required('An Application Name is required'),
  platform: Yup.string().required('A platform is required'),
  redirectUrl: Yup.string().required('An App Store URL is required'),
  firstVersion: Yup.string().required('An initial version is required'),
});

function CreateAppDialog({ open, organization, handleSuccess, handleClose }) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      appName: '',
      platform: '',
      redirectUrl: '',
      firstVersion: '',
    },
    validationSchema: CreateAppSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const data = {
          name: values.appName,
          platform: values.platform,
          redirect_url_attributes: { redirect_url: values.redirectUrl.trim() },
          versions_attributes: [ { version_number: values.firstVersion.trim() }]
        }
        await client.createApp(organization, data)
        handleSuccess();
      } catch (e) {
        const internalError = e.response.data;
        if (!!internalError) {
          const code = internalError.errors.map(e => e.error_code)[0];
          const message = internalError.errors.map(e => e.message)[0];
          if (isMountedRef.current) {
            setSubmitting(false);
            setErrors({ afterSubmit: code });
            enqueueSnackbar(message, { variant: 'error' });
          }
        }

        if (e.message === "Network Error") {
          enqueueSnackbar('There was a problem connecting to the server', { variant: 'error' });
        }
      }
    }
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps
  } = formik;

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"sm"} aria-labelledby="form-dialog-title" onClose={handleClose}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add A New App</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              select
              fullWidth
              label="Select Platform"
              {...getFieldProps('platform')}
              error={Boolean(touched.platform && errors.platform)}
              helperText={Boolean(touched.platform && errors.platform)}
            >
              {platforms.map((platform) => {
                return (
                  <MenuItem key={platform} value={platform}>
                    {platform}
                  </MenuItem>
                )
              })}
            </TextField>
            <Box sx={{ mb: 2 }} />
            <TextField
              {...getFieldProps('appName')}
              label="App Name"
              fullWidth
              error={
                Boolean(touched.appName && errors.appName)
              }
              helperText={touched.appName && errors.appName}
            />
            <Box sx={{ mb: 2 }} />
            <TextField
              {...getFieldProps('redirectUrl')}
              label="App Store Url"
              fullWidth
              error={
                Boolean(touched.redirectUrl && errors.redirectUrl)
              }
              helperText="The App Store URL users will use to download new versions of this app."
            />
            <Box sx={{ mb: 2 }} />
            <TextField
              {...getFieldProps('firstVersion')}
              label="Initial Version Number"
              fullWidth
              error={
                Boolean(touched.firstVersion && errors.firstVersion)
              }
              helperText={touched.firstVersion && errors.firstVersion}
            />
            <DialogActions>
              <LoadingButton
                size="medium"
                variant="contained"
                pending={isSubmitting}
              >
                Create App
              </LoadingButton>
            </DialogActions>
          </DialogContent>
        </Form>
      </FormikProvider>
    </Dialog>
  )
}

export default CreateAppDialog;
