import { PATH_APP } from './paths';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'layouts/DashboardLayout';
import DashboardEcommerceView from 'views/general/DashboardAnalyticsView/index';

// ----------------------------------------------------------------------

export function AppRoutes(isLoggedIn, user, organizations, organization, apps, changeOrg) {
  const result = {
    path: PATH_APP.root,
    guard: null,
    layout: DashboardLayout,
    routes: [

      {
        exact: true,
        path: PATH_APP.main.dashboard,
        component: () => <DashboardEcommerceView apps={apps} organization={organization} />
      },
      {
        exact: true,
        path: PATH_APP.root,
        component: () => <Redirect to={PATH_APP.main.root} />
      },
      {
        exact: true,
        path: PATH_APP.management.user.account,
        component: lazy(() => import('../views/user/AccountView'))
      },
      {
        exact: true,
        path: PATH_APP.management.root,
        component: () => <Redirect to={PATH_APP.management.user.profile} />
      },
      {
        path: `/app/view/:id`,
        component: lazy(() => import('../views/app/AppView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  };

  return result;
};
