import React, { Component } from 'react';
import {
  Grid,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Switch,
  TableBody
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { withStyles } from '@material-ui/styles';
import client from 'client';

import CreateVersionModal from 'components/CreateVersion/modal';
import EditVersionModal from 'components/EditVersion/modal';
import DeleteVersionAlert from 'components/DeleteVersion/alert';

const styles = {
  smallBottomMargin: {
    marginBottom: 8
  },
  emptyState: {
    minHeight: '30vh',
  },
  smallTopMargin: {
    marginTop: 8,
  }
}

class VersionList extends Component {
  state = {
    versions: [],
    publishModalOpen: false,
    editModalOpen: false,
    editVersion: null,
    newVersionNumber: null,
    newVersionEnabled: false,
    newVersionForceUpgrade: false,
    deleteModalOpen: false,
    deleteVersion: null,
    anchorEl: null,
  }

  componentDidMount() {
    const { app } = this.props;

    this.fetchVersions(app);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.app.id !== prevProps.app.id) {
      this.fetchVersions(this.props.app);
    }
  }

  fetchVersions = (app) => {
    client.fetchAppVersions(app.id).then((res) => {
      this.setState({
        versions: res.versions
      })
    });
  }

  updateForceUpgrade = (version) => {
    const { versions } = this.state;
    const data = { force_upgrade: !version.force_upgrade }

    const versionidx = versions.findIndex((v) => {
      return v.id === version.id
    });

    client.updateVersion(version.id, data).then((res) => {
      versions[versionidx] = res;
      this.setState({
        versions: versions
      });
    }).catch((e) => {
      console.log(e);
    });
  }

  updateVersionEnabled = (version) => {
    const { versions } = this.state;
    const data = { enabled: !version.enabled };
    const versionidx = versions.findIndex((v) => {
      return v.id === version.id
    });

    client.updateVersion(version.id, data).then((res) => {
      versions[versionidx] = res;
      this.setState({
        versions: versions
      });
    }).catch((e) => {
      console.log(e);
    });
  }

  deleteVersion = () => {
    const { app } = this.props;
    const { deleteVersion } = this.state;

    client.deleteVersion(deleteVersion.id).then((res) => {
      this.setState({
        anchorEl: null,
        deleteVersion: null,
        deleteModalOpen: false
      }, () => this.fetchVersions(app));
    }).catch((e) => {
      console.log(e);
    });
  }

  toggleEditModal = () => {
    const { editModalOpen } = this.state;

    this.setState({
      editModalOpen: !editModalOpen,
    });
  }

  toggleModal = () => {
    const { publishModalOpen } = this.state;

    this.setState({
      publishModalOpen: !publishModalOpen,
    });
  }

  handleOptionsOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  }

  handleOptionsClose = () => {
    this.setState({
      anchorEl: null
    });
  }

  toggleDeleteVersionModal = (version) => {
    const { deleteModalOpen } = this.state;

    this.setState({
      deleteModalOpen: !deleteModalOpen,
      deleteVersion: version
    });
  }

  render() {
    const { versions, publishModalOpen, editVersion, editModalOpen, anchorEl, deleteModalOpen, deleteVersion } = this.state;
    const { classes, app } = this.props;

    return (
      <Grid container direction="row" item xs={12}>
        <Grid container direction="row" item xs={12} justifyContent="flex-end" alignItems="center" className={classes.smallBottomMargin}>
          <Grid item xs={3}>
            <Button
              onClick={this.toggleModal}
              variant="outlined"
              size="medium"
              fullWidth
              startIcon={<AddCircleIcon />}
            >
              Publish a New Version
            </Button>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Typography>Version Number</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Typography>Version App Store URL</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container direction="row">
                    <Grid item xs={12}>
                      <Typography>Enabled</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid container direction="row" spacing={1}>
                    <Grid item>
                      <Typography>Force Upgrade </Typography>
                    </Grid>
                    <Grid item>
                      <Tooltip title="When force upgrade is enabled, users will be blocked from app using the current version of their app. When force upgrade is disabled, users will be prompted to upgrade their apps, but will be able to dismiss the prompt." placement="right">
                        <HelpOutlineOutlinedIcon color="secondary" />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Typography>Options</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
                {versions.map((version) => (
                  <TableRow key={version.id}>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {version.version_number}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle2">
                        {!!version.redirect_url ? (version.redirect_url) : (app.redirect_url) }
                      </Typography>
                    </TableCell>
                    <TableCell >
                      <Switch
                        onChange={() => { this.updateVersionEnabled(version) }}
                        checked={version.enabled}
                        inputProps={{ 'aria-label': 'primary checkbox' }} />
                    </TableCell>
                    <TableCell>
                      <Switch
                        onChange={() => this.updateForceUpgrade(version)}
                        checked={!Boolean(version.force_upgrade)}
                        inputProps={{ 'aria-label': 'secondary-checkbox' }}
                      />
                    </TableCell>
                    <TableCell>
                      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleOptionsOpen}>
                        <MoreHorizIcon />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleOptionsClose}
                      >
                        <MenuItem onClick={() => this.setState({ editVersion: version }, this.toggleEditModal)}>Edit</MenuItem>
                        <MenuItem onClick={() => this.toggleDeleteVersionModal(version)}>Delete</MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
          </Table>
        </TableContainer>
        {versions.length === 0 &&
          <Grid className={classes.emptyState} container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={3}>
              <Typography variant="h4" align="center">
                Add Your First Version
              </Typography>
              <Button className={classes.smallTopMargin} fullWidth onClick={this.toggleModal} variant="contained" size="large" startIcon={<AddCircleIcon />}>Publish New Version</Button>
            </Grid>
          </Grid>
        }
        <CreateVersionModal app={app} handleClose={this.toggleModal} handleSuccess={() => { this.toggleModal(); this.fetchVersions(app) }} open={publishModalOpen} />
        {!!editVersion &&
          <EditVersionModal app={app} version={editVersion} handleClose={this.toggleEditModal} handleSuccess={() => { this.toggleEditModal(); this.fetchVersions(app); this.setState({ editVersion: null, anchorEl: null }); }} open={editModalOpen} />
        }
        {!!deleteVersion &&
          <DeleteVersionAlert handleClose={this.toggleDeleteVersionModal} deletingVersion={deleteVersion} handleDelete={this.deleteVersion} open={deleteModalOpen} />
        }
      </Grid>
    )
  }
}

export default withStyles(styles)(VersionList);
